<template>
	<div class="landing-wrapper">
        <t-header />
		<div class="container space-top-3 space-bottom-2">
			<div class="w-lg-80 mx-lg-auto">
				<div class="mb-4">
					<h1 class="h2">
						Thriftax Terms and Conditions
					</h1>
					<p class="lead">
						Effective: January 29th, 2021
					</p>
				</div>
				<p>
					These Terms and Conditions (“Terms”) set forth the terms and
					conditions that apply to your access and use of the internet web site
					and it’s software-based tax returns services (the “Services”) located
					at www.thriftax.com (the “Site”) and their deliverable products (the
					“Products”). The Site and Services are operated by Latax Technologies,
					LLC. (DBA “Thriftax”), a Wyoming limited liability company. All
					Services are provided in accordance with and pursuant to the Client
					Agreement (“Agreement”) currently in effect, to which Clients (defined
					below) must agree to be bound. The term “you” refers to a visitor or a
					client of the Services (a “Client”). The terms “we,” “our” or “us”
					refer to Thriftax.
				</p>
				<p>
					THE TERMS INCLUDE A CLASS ACTION WAIVER AND REQUIRE BINDING
					ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
					JURY TRIALS.
				</p>
				<p>
					IF YOU SUBSCRIBE TO SERVICES FOR A TERM (THE “INITIAL TERM”), THEN
					THESE TERMS WILL BE AUTOMATICALLY RENEWED FOR ADDITIONAL PERIODS OF
					THE SAME DURATION AS THE INITIAL TERM AT OUR THEN-CURRENT FEE FOR THE
					SERVICES UNLESS YOU DECLINE TO RENEW YOUR SUBSCRIPTION IN ACCORDANCE
					WITH THESE TERMS.
				</p>
				<h3 class="mt-4">
					1. Accepting the Terms
				</h3>
				<p>
					PLEASE READ THESE TERMS CAREFULLY. BY ACCESSING OR USING THE SITE OR
					THE SERVICES, SIGNING UP FOR THE SERVICES, AND/OR MERELY BROWSING THE
					SITE, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE TO
					BE BOUND BY THESE TERMS, (2) YOU ARE OF LEGAL AGE IN THE JURISIDICTION
					WHERE YOU RESIDE TO CONSUME THE SERVICES AND CONSENT TO THESE TERMS.
					IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU MAY NOT ACCESS OR
					USE THE SITE OR THE SERVICES.
				</p>

				<h3 class="mt-4">
					2. Privacy Policy
				</h3>
				<p>
					We value your right to privacy and take steps to protect it. For more
					information, please refer to our Privacy Policy, located at:
					www.thriftax.com/privacy-policy.
				</p>
				<h3 class="mt-4">
					3. Description of Services
				</h3>
				<p>
					Our Services provide a web-based US tax-return filing services,
					designed for nonresident aliens to comply with their IRS and, if
					required, (US) state/s tax return filing obligations. Our Services
					generate filled, ready-to-print tax-return and/or other tax related
					forms based on answers entered by the client, along with post-filing
					instructions about form signing and mailing requirements. Our Services
					do not, and cannot, ensure the client is answering the questions
					correctly, and it is the sole responsibility of the client to verify,
					check and double-check the answers typed are correct, full, and not
					missing or omitting information.
				</p>
				<p>
					THRIFTAX CANNOT ALWAYS FORESEE OR ANTICIPATE TECHNICAL OR OTHER
					DIFFICULTIES WHICH MAY RESULT IN FAILURE TO OBTAIN DATA OR LOSS OF
					DATA, OR ANY OTHER SERVICES INTERRUPTIONS. WE USE REASONABLE CARE,
					CONSISTENT WITH SOUND INDUSTRY PRACTICE, IN PROVIDING OUR SERVICES.
					OUR SERVICES ARE PROVIDED FOR CONVENIENCE AND WE CAN NOT GUARANTEE
					THEIR ACCURACY. WE DO NOT GUARANTEE THAT OUR SERVICES, COMMUNICATIONS,
					OR ANY CONTENT WILL BE DELIVERED TO YOU UNINTERRUPTED, TIMELY, SECURE,
					OR ERROR-FREE. ERRORS, WILL BE CORRECTED IF AND WHERE DISCOVERED
					THRIFTAX DOES NOT GUARANTEE THAT THE ANY TAX REFUNDS OR TAX
					LIABILITIES AMOUNTS PRESENTED TO THE CLIENT WILL BE ACCEPTED BY, FULLY
					ADOPTED BY, PAID TO YOU BY PAID BY YOU TO THE IRS OR ANY OTHER TAX
					AUTHORITIES. THE ULTIMATE DECISION ABOUT YOUR TAX REFUND OR TAX
					LIABLITY AMOUNT IS OF THE IRS AND OR OF OTHER RELEVANT TAX AUTHORITY,
					YOU CAN ALWAYS ACCESS YOUR ESRA DIRECTLY THROUGH YOUR CUSTODIAN.
				</p>
				<p>
					This Site is for the use of Clients and potential Clients of Thriftax.
					It is not to be used by any other tax advisor or tax professional as
					an accuracy or validation tool.
				</p>
				<h3 class="mt-4">
					4. Purchases and Fee Payments
				</h3>
				<p>
					A Purchase is considered completed and final right after a payment is
					billed and processed. Thriftax provides any purchased product/s
					immediately post-payment. Form/s will be available for you to download
					in the Site only. For security reasons and Thriftax is unable to
					deliver forms over email.
				</p>
				<p>
					You agree to pay all fees or charges to your Account in accordance
					with the fees, charges, and billing terms in effect at the time of
					checkout and prior to receiving any forms or other deliverable
					products from Thriftax. Such fees and charges include, without
					limitation, the fee for your subscription to the Services. Given the
					nature of the products – pre-filled and auto calculated tax-return and
					other tax related forms delivered electronically, all fees are
					non-refundable except as otherwise described herein or in our billing
					terms in effect at the time a fee or charge is payable. Without
					prejudice to the foregoing, in the case of duplicate payment (double
					charge for a single identical product) or accidental over-payment
					caused by Thriftax’s software error, Thriftax may, at its sole
					discretion, issue a refund.
				</p>
				<p>
					Your payment will always be billed in US dollars. Thriftax is not
					responsible to any currency conversion fees charged by your
					credit/debit card or digital wallet provider.
				</p>
				<p>
					Thriftax reserves the right to change its products and services prices
					any time and at its sole discretion and without providing any sort of
					notice. Purchases completed any time prior a price change are not
					eligible for a “price match” or partial refunds.
				</p>
				<p>
					Thriftax offers a Refer-a-Friend program. To qualify for a
					refer-a-friend payment bonus, you must refer a non-registered client
					to sign up and create an account with Thriftax. Your referred friend
					must use coupon code REFER10 for you to get paid. The coupon code will
					automatically be applied at checkout of referred clients, however any
					referred client will be able to manually edit their coupon code and
					elect to use a different coupon code. In case your referred client
					uses any other code you will not be eligible for your referral
					payment.
				</p>
				<h3 class="mt-4">
					5. Your Registration Information
				</h3>
				<p>
					To access our Services you must first register for an account
					(&ldquo;Account&rdquo;) and electronically sign our Agreement. Our
					Site is the only means of accessing our Services.
				</p>
				<p>
					You agree and understand that you are responsible for maintaining the
					confidentiality of your password that, together with your Login ID
					(i.e., your email address), allows you to access our Services. That
					Login ID and password, together with any other registration
					information, constitute your &ldquo;Registration Information.&rdquo;
					You represent and warrant that all required Registration Information
					you submit is true, accurate, current and complete.
				</p>
				<p>
					You agree not to create an Account or use the Services if you have
					been previously removed by us or banned from the Services. You agree
					not to create an Account using a false identity or information, or on
					behalf of someone other than yourself. We reserve the right in our
					sole discretion to suspend or terminate your Account and/or refuse any
					and all current or future use of the Services (or any portion thereof)
					at any time for any reason. You agree that we will not liable to you
					or to any third party for any suspension or termination of your
					Account or any refusal of any use of the Services (or any portion
					thereof). We reserve the right in our sole discretion to remove any
					content that you provide and/or upload to the Services at any time for
					any reason, including, but not limited to, information you provide for
					your Account. You agree that we will not be liable to you or to any
					third party for such removal.
				</p>
				<h3 class="mt-4">
					6. Your Use of Services
				</h3>
				<p>
					Your use of our Services is personal to you and you are only granted a
					limited right to use the Services and only for the purposes and by the
					means described by Thriftax and set forth in this Agreement. Subject
					to these Terms, we grant you a non-exclusive, revokable, limited
					access the Services, solely for your own personal, non-commercial use
					and for lawful purposes only. You will need to subscribe to our
					Services in order to file your tax return or other tax-related forms.
				</p>
				<p>
					Web-based tax-filing services have limitations that prospective
					customers should consider before subscribing to use the Site or our
					Services. Specifically, in a web-based tax-filing, you will not
					receive tax advice face-to-face, by telephone, or other individualized
					interaction with a tax or legal professional. Our Services use our
					web-based application to facilitate the Client/advisor relationship.
					Support provided by telephone, live chat, email, and text is for the
					express purpose of assisting in clarification of questions, technical
					matters, subscription setup, and other instructions related to the
					usability of the Services.
				</p>
				<p>
					We reserve the right, at any time, to modify, suspend, or discontinue
					the Services (in whole or in part) with or without notice to you. You
					agree that we will not be liable to you or to any third party for any
					modification, suspension, or discontinuation of the Services or any
					part thereof.
				</p>
				<p>
					Unless otherwise indicated, any future release, update, or other
					addition to functionality of the Services will be subject to these
					Terms. We make no representation that the Services are appropriate for
					use in locations other than the United States.
				</p>
				<p>
					You acknowledge and agree that we will have no obligation to provide
					you with any support or maintenance in connection with the Services.
					You agree that we are not obligated to create or provide any
					corrections, updates, upgrades, bug fixes, and/or enhancements of the
					Services (each an &ldquo;Update&rdquo;). However, in the event we
					offer an Update, you agree that we may amend these Terms in connection
					with such Update without specific notice to you and that your use of
					the Services following such Update is conditioned upon your acceptance
					of any revised Terms. By using the Services following an Update, you
					are representing that you have reviewed the then-current version of
					these Terms and agree to be bound by such version.
				</p>
				<p>
					You agree that Thriftax may use your feedback, social media content,
					suggestions, or ideas (&ldquo;Feedback&rdquo;) in any way, including
					in future modifications of our Services, other products or services,
					advertising, or marketing materials and that the submission of any
					Feedback is at your own risk and that we have no obligations
					(including without limitation obligations of confidentiality) with
					respect to such Feedback. You grant Thriftax a perpetual, worldwide,
					fully transferable, sublicensable, irrevocable, fully paid-up,
					royalty-free license to use, reproduce, perform, display, distribute,
					adapt, modify, re-format, create derivative works of, and otherwise
					commercially or non-commercially exploit in any manner we deem
					appropriate the Feedback you provide to Thriftax and to sublicense the
					foregoing rights. Thriftax will not sell, publish or share your
					feedback in a way that could identify you without your explicit
					consent.
				</p>
				<p>
					Thriftax does not guarantee continuous, uninterrupted, or secure
					access to Thriftax.com or any other website.
				</p>
				<h3 class="mt-4">
					7. Electronic Communication; Notices
				</h3>
				<p>
					In order to use our Services, you consent and agree to receive all
					required notices electronically through your email address or via
					notices on the Site and agree that all terms and conditions,
					agreements, notices, disclosures, and other communications that we
					provide to you electronically satisfy any legal requirement that such
					communications would satisfy if it were in a hardcopy writing. The
					foregoing does not affect your non-waivable rights. Where Thriftax
					requires that you provide an e-mail address, you are responsible for
					providing us with your most current e-mail address. It is your
					responsibility to update your email address when necessary. You
					acknowledge and agree that all communications sent to you at your
					email address on record will be treated as if they were delivered to
					you personally, whether or not you receive them. If the last e-mail
					address you provided to Thriftax is not valid, or for any reason is
					not capable of delivering to you any notices required/ permitted by
					these Terms, our dispatch of the e-mail containing such notice will
					nonetheless constitute effective notice.
				</p>
				<p>
					Thriftax provides you Thriftax&rsquo;s email addresses so that you may
					communicate with us electronically via email. All email sent to and
					from Thriftax will be received or otherwise recorded by Thriftax and
					is subject to archival, monitoring or review by and/or disclosure to,
					someone other than the recipient. Communications through Thriftax.com
					may involve the electronic transmission, to any email address you
					provided to us, of information that you may consider to be personal
					tax-related information and you agree and consent to such transmission
					of such information. It is your responsibility to update or change
					your email address, as appropriate.
				</p>
				<h3 class="mt-4">
					8. User Content and Delivered Products
				</h3>
				<p>
					You are solely responsible for your User Content. You assume all risks
					associated with use of your User Content. You represent and warrant
					that your User Content does not violate these Terms. You may not
					represent or imply to others that your User Content is in any way
					provided, sponsored, or endorsed by us. Because you alone are
					responsible for your User Content, you may expose yourself to
					liability if, for example, your User Content violates these Terms. We
					are not obligated to backup any User Content, and your User Content
					may be deleted at any time without prior notice. You are solely
					responsible for creating and maintaining your own backup copies of
					your User Content if you desire. We will not be liable for the
					deletion or accuracy of any User Content; the failure to store,
					transmit, or receive transmission of User Content; or the security,
					privacy, storage, or transmission of other communications originating
					with or involving use of the Services. You acknowledge that we have no
					obligation to pre-screen User Content, although we reserve the right
					in our sole discretion to pre-screen, refuse, or remove any User
					Content at any time for any reason. PLEASE MAKE SURE THAT YOU ONLY
					PROVIDE INFORMATION TO THE SERVICES THAT YOU ARE ALLOWED TO PROVIDE
					WITHOUT VIOLATING ANY OBLIGATIONS YOU MIGHT HAVE TOWARDS A THIRD
					PARTY, INCLUDING ANY CONFIDENTIALITY OBLIGATIONS. PLEASE DO NOT
					PROVIDE ANY INFORMATION THAT YOU ARE NOT ALLOWED TO SHARE WITH
					OTHERS.
				</p>
				<p>
					Delivered products that you paid for are available for you to download
					immediately after successfully checking out and paying for your
					form/s. Thriftax is responsible to maintain and keep your purchased
					products accessible to you for a minimum of 30 days after completing
					the purchase. After 30 days, Thriftax will no longer be responsible to
					provide online access to any purchased products and it is your sole
					responsibility to save copies of any delivered products for future
					reference of yourself and/or of federal/state/local tax
					authorities.
				</p>
				<p>
					We reserve the right (but have no obligation) to review any User
					Content, and to investigate and/or take appropriate action against you
					in our sole discretion if you violate these Terms or otherwise create
					liability for us or any other person. Such action may include removing
					or modifying your User Content, terminating your Account, and/or
					reporting you to law enforcement authorities.
				</p>
				<h3 class="mt-4">
					Thriftax&rsquo;s intellectual property rights
				</h3>
				<p>
					Excluding any User Content you may provide, you acknowledge and agree
					that (a) the Site and Services and the contents of the Site and
					Services, including, without limitation, its &ldquo;look and
					feel&rdquo; (e.g., text, graphics, images, logos, and button icons),
					photographs, editorial content, notices, software of any kind, and
					other materials, are protected under both United States and other
					applicable copyright, trademark and other laws; and (b) the Site and
					Services and the contents of the Site and Services, and all
					intellectual property rights therein, are owned by Thriftax. Our name,
					logo, and the product names associated with the Site and Services
					belong to us, and no right or license is granted to use them by
					implication, estoppel, or otherwise. Neither these Terms nor your
					access to the Site or the Services transfers to you or any third party
					any rights, title, or interest in or to such intellectual property
					rights, except for the limited access rights expressly set forth
					herein. We reserve all rights not granted in these Terms. There are no
					implied licenses granted under these Terms. You may download or print
					a copy of your purchased downloadable products available on the Site
					after completing a payment and for your personal, internal, and
					non-commercial use only. You are not allowed to make a copy, print,
					download, screenshot or save any part of the questionnaire that is not
					explicitly meant to be copied, printed, downloaded, captured in a
					screenshot, or saved.
				</p>
				<h3 class="mt-4">
					10. Restrictions
				</h3>
				<p>
					The rights granted to you in these Terms are subject to the following
					restrictions:
				</p>
				<p>You will NOT:</p>
				<p>
					License, sell, rent, lease, transfer, assign, distribute, host, or
					otherwise commercially exploit the Site or the Services, whether in
					whole or in part, or any content displayed on the Site or the Services
				</p>
				<p>
					Frame or utilize framing techniques to enclose any trademark or logo
					or other portion of the Site or the Services (including images, text,
					page layout or form)
				</p>
				<p>
					Use any metatags or other &ldquo;hidden text&rdquo; using our name or
					trademarks
				</p>
				<p>
					Modify, translate, adapt, merge, or make derivative works of any part
					of the Site or the Services except to the extent the foregoing
					restrictions are expressly prohibited by applicable law
				</p>
				<p>
					Access the Site or the Services in order to build a similar or
					competitive website, product, or service
				</p>
				<p>
					Except as expressly allowed herein, copy, reproduce, distribute,
					republish, download, display, post, or transmit, in any form or by any
					means, any part of the Site or the Services
				</p>
				<p>
					Remove or destroy any copyright notices or other proprietary markings
					contained on or in the Site or the Services (or on any content
					displayed on the Site or the Services)
				</p>
				<p>
					Use any robot, spider, scraper, deep link or other similar automated
					data gathering or extraction tool, program, algorithm, or methodology
					to access, acquire, copy, or monitor the Site or the Services, or any
					portion thereof, without Thriftax&rsquo;s express written consent,
					which may be withheld in Thriftax&rsquo;s sole discretion
				</p>
				<p>
					Use or attempt to use any engine, software, tool, agent, or other
					device or mechanism (including, without limitation, browsers, spiders,
					robots, avatars, or intelligent agents) to navigate or search
					Thriftax.com, other than the search engines and search agents
					available through our Services and other than generally available
					third-party web browsers (such as Microsoft Explorer)
				</p>
				<p>
					Post, upload, distribute, or transmit to or through the Site or the
					Services any file which contains viruses, worms, Trojan horses or any
					other contaminating or destructive features, or that otherwise
					interfere with the proper working of Thriftax.com or our Services
				</p>
				<p>
					Use the Site or the Services to harvest, collect, gather, or assemble
					information or data regarding other users without their consent
				</p>
				<p>
					Interfere with, disrupt, or create an undue burden on servers or
					networks connected to the Site or the Services, or violate the
					regulations, policies, or procedures of such networks
				</p>
				<p>
					Attempt to gain unauthorized access to the Site or the Services
				</p>
				<p>
					Attempt to decipher, decompile, disassemble, or reverse-engineer any
					of the software comprising or in any way making up a part of the Site
					or our Services; or take any action or make available any User Content
					on or through the Site or the Services that: (A) violates any
					third-party right; or (B) is in violation of any laws, or obligations
					or restrictions imposed by any third party.
				</p>
				<h3 class="mt-4">
					Disclaimer of representations and warranties
				</h3>
				<p>
					YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT
					PERMITTED BY APPLICABLE LAW, THE CONTENT AND ALL SERVICES AND PRODUCTS
					ASSOCIATED WITH OR PROVIDED THROUGH THE SITE OR THE SERVICES ARE
					PROVIDED TO YOU ON AN &ldquo;AS-IS&rdquo; AND &ldquo;AS
					AVAILABLE&rdquo; BASIS, WITH ALL FAULTS AND THRIFTAX AND ITS PARENTS,
					SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, SUPPLIERS, LICENSORS,
					AGENTS, SHAREHOLDERS, AND EMPLOYEES MAKE NO, AND HEREBY EXPRESSLY
					DISCLAIM ALL, CONDITIONS, REPRESENTATIONS OR WARRANTIES OF ANY KIND,
					WHETHER STATUTORY, EXPRESS, OR IMPLIED, AS TO THE SITE OR THE
					SERVICES, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES AND/OR
					CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
					TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. YOU EXPRESSLY
					AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK.
				</p>
				<p>
					NEITHER THRIFTAX NOR ITS PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS,
					DIRECTORS, SUPPLIERS, LICENSORS, AGENTS, SHAREHOLDERS, OR EMPLOYEES
					MAKE ANY REPRESENTATIONS, WARRANTIES, OR CONDITIONS, STATUTORY,
					EXPRESS, OR IMPLIED, REGARDING THE ACCURACY, RELIABILITY, OR
					COMPLETENESS OF THE SITE OR THE SERVICES, THAT THE SITE OR THE
					SERVICES WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE SITE
					OR THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS
					IN THE SITE OR THE SERVICES WILL BE CORRECTED, THAT THE CONTENT THAT
					MAY BE AVAILABLE THROUGH THE SITE OR THE SERVICE IS FREE OF INFECTION
					FROM ANY VIRUSES OR OTHER CODE OR COMPUTER PROGRAMMING ROUTINES THAT
					CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES OR THAT ARE INTENDED
					TO DAMAGE, SURREPTITIOUSLY INTERCEPT, OR EXPROPRIATE ANY SYSTEM, DATA,
					OR PERSONAL INFORMATION.
				</p>
				<h3 class="mt-4">
					12. No legal or tax advice
				</h3>
				<p>
					NONE OF THRIFTAX, THRIFTAX.COM OR OUR SERVICES ARE INTENDED TO PROVIDE
					LEGAL OR TAX ADVICE. THRIFTAX IS NOT A LEGAL ADVISOR OR TAX ADVISOR.
					Before making any final decisions regarding to how you should answer a
					question and/or include in your tax filing questionnaire and/or
					disclose any piece of information, you should consider obtaining
					additional information and advice from your accountant, lawyer, or
					other advisor who are familiar with your individual circumstances.
				</p>
				<h3 class="mt-4">
					13. Limitation of liability
				</h3>
				<p>
					TO THE MAXIMUM EXTENT PERMITTED BY LAW, NONE OF THRIFTAX OR ITS
					PARENTS, SUBSIDIARIES, OWNERS, PARTNERS, AFFILIATES, OFFICERS,
					DIRECTORS, SUPPLIERS, LICENSORS, AGENTS, SHAREHOLDERS, OR EMPLOYEES
					SHALL BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY, WHETHER
					IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, FOR
					ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY,
					LIQUIDATED, OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS
					OF PROFIT, REVENUE, OR BUSINESS, ARISING IN WHOLE OR IN PART FROM YOUR
					USE OF OR ACCESS TO THE SITE OR THE SERVICES, EVEN IF THRIFTAX HAS
					BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION
					INCLUDES, WITHOUT LIMITATION, THE TRANSMISSION OF ANY VIRUSES, DATA,
					OR HARMFUL CODE THAT MAY AFFECT YOUR EQUIPMENT OR ANYONE ELSE&rsquo;S
					EQUIPMENT, ANY INCOMPATIBILITY BETWEEN THRIFTAX.COM&rsquo;S FILES AND
					YOUR BROWSER OR OTHER WEBSITE ACCESSING PROGRAM, OR ANY FAILURE OF ANY
					ELECTRONIC OR TELEPHONE EQUIPMENT, COMMUNICATION, OR CONNECTION LINES,
					UNAUTHORIZED ACCESS, THEFT, OPERATOR ERRORS, OR ANY FORCE MAJEURE.
				</p>
				<p>
					TO THE EXTENT ALLOWED BY LAW, YOU AND THRIFTAX EACH WAIVE ANY RIGHT TO
					PURSUE DISPUTES ON A CLASSWIDE BASIS; THAT IS, TO EITHER JOIN A CLAIM
					WITH THE CLAIM OF ANY OTHER PERSON OR ENTITY, OR ASSERT A CLAIM IN A
					REPRESENTATIVE CAPACITY ON BEHALF OF ANYONE ELSE IN ANY LAWSUIT,
					ARBITRATION, OR OTHER PROCEEDING.
				</p>
				<p>
					TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO
					THE CONTRARY IN THESE TERMS, THE LIABILITY OF THRIFTAX AND ITS
					PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, SUPPLIERS,
					LICENSORS, AGENTS, SHAREHOLDERS, OR EMPLOYEES TO YOU FOR ANY CAUSE
					WHATEVER AND REGARDLESS OF THE FORM OF THE ACTION WILL AT ALL TIMES BE
					LIMITED TO THE TOTAL AMOUNT YOU HAVE PAID TO THRIFTAX IN FEES IN THE
					12 MONTHS PRECEDING THE CLAIM. THE EXISTENCE OF MORE THAN ONE CLAIM
					WILL NOT ENLARGE THIS LIMIT.
				</p>
				<p>
					SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
					LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
					LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. THE LIMITATIONS OF
					DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE
					BARGAIN BETWEEN US AND YOU.
				</p>
				<h3 class="mt-4">
					14. Modifications
				</h3>
				<p>
					Thriftax may modify these Terms from time to time. We will notify you
					of changes to these Terms by posting the new Terms at
					thriftax.com/terms&amp;conditions/ and updating the &ldquo;Last
					Updated&rdquo; date above. If we make any material changes, we may
					notify you by sending you an email to the last email address you
					provided to us (if any). Any changes to these Terms will be effective
					upon the earlier of 30 calendar days following our dispatch of an
					email notice to you or posting of the new Terms (if applicable). These
					changes will be effective immediately for new users of the Services,
					who at the date of changes, have not completed a purchase. Continued
					use of the Services following notice of such changes will indicate
					your acknowledgement of such changes and agreement to be bound by the
					terms and conditions of such changes. PLEASE REGULARLY CHECK THE SITE
					TO VIEW THE THEN-CURRENT TERMS.
				</p>
				<h3 class="mt-4">
					15. Browser support
				</h3>
				<p>
					Thriftax is not committed to support any specific browser. If you
					believe the Service isn&rsquo;t functioning as it meant to, or if you
					are experiencing any sort of errors and/or other technical
					difficulties, contact us prior to completing check-out to ensure
					accuracy of your captured data.
				</p>
				<h3 class="mt-4">
					16. Third party links
				</h3>
				<p>
					The Services may contain links to third-party websites and services
					(collectively, &ldquo;Third-Party Links&rdquo;). Such Third-Party
					Links are not under our control, and we will not be liable for any
					Third-Party Links. We provide access to these Third-Party Links only
					as a convenience to you, and we do not review, approve, monitor,
					endorse, warrant, or make any representations with respect to
					Third-Party Links. You use all Third-Party Links at your own risk, and
					should apply a suitable level of caution and discretion in doing
					so.
				</p>
				<h3 class="mt-4">
					17. Miscellaneous
				</h3>
				<p>
					These Terms, together with the Agreement, constitute the entire
					agreement between you and us regarding the use of the Site and the
					Services. The section titles in these Terms are for convenience only
					and have no legal or contractual effect. The word
					&ldquo;including&rdquo; means &ldquo;including without
					limitation&rdquo;. If any provision contained in your Agreement or
					these Terms is or becomes invalid, illegal, or unenforceable in whole
					or in part, such invalidity, legality, or unenforceability shall not
					affect the remaining provisions and portions of your Agreement or
					these Terms and the invalid, illegal, or unenforceable provision will
					be deemed modified so that it is valid and enforceable to the maximum
					extent permitted by law. Our failure to enforce a provision of these
					Terms is not a waiver of our right to do so later.
				</p>
				<p>
					This Agreement, along with any Supplemental Agreements, is the entire
					and exclusive agreement between the parties, and it supersedes all
					previous communications, representations or agreements, either oral or
					written, between them with respect to this subject matter. No
					representations or statements of any kind made by us, which are not
					included in this Agreement, shall be binding on us.
				</p>
				<p>
					You may not modify or amend this Agreement in whole or in part without
					the prior written consent of one of our authorized representatives. We
					may replace or amend this Agreement from time to time by posting new
					terms of service to this Website. Please check the Terms of Use
					periodically for changes. Your subsequent use of the Website, or any
					content, products, services or materials provided through the Website,
					will be subject in all respects to the terms of service in force at
					the time of such subsequent use.
				</p>
				<p>
					No waiver of any provision herein shall be valid unless in writing and
					signed by both our authorized representative and you. Our failure to
					insist upon or enforce strict performance of any provision of this
					Agreement or any right shall not be construed as a waiver of any such
					provision or right.
				</p>
				<p>
					We may assign our rights and duties under this Agreement to any party
					at any time without notice to you.
				</p>
				<p>
					We will not be liable for any delay or failure to perform resulting
					from causes outside its reasonable control, including, but not limited
					to, acts of God, war, terrorism, riots, embargos, acts of civil or
					military authorities, fire, floods, accidents, strikes or shortages of
					transportation facilities, fuel, energy, labor or materials.
				</p>
				<p>
					All trademarks, logos and service marks (&ldquo;Marks&rdquo;)
					displayed on the Site or used in connection with the Services are our
					property or the property of other third parties. You are not permitted
					to use these Marks without our prior written consent or the consent of
					such third party that may own the Marks.
				</p>
				<h3 class="mt-4">
					18. Questions or concerns
				</h3>
				<p>
					Reach out to us. If you have questions, comments, concerns or feedback
					for these Terms, our Privacy Policy or any security concern, send an
					email to: info@thriftax.com.
				</p>
			</div>
		</div>
        <t-footer />
	</div>
</template>

<script>
export default {
};
</script>
